import { ImportMessage, NotificationType } from '../Types/Notifications.types';
import { handleInputNotification } from '../Slices/Dataset.slice';
import { addNotification, addUserNotification } from '../Slices/Notifications.slice';
import { setTemporaryImportNotificationStatusWidgetState } from '../../component/status-widget/statusWidget.slice';
import { AppDispatch } from '../../app/store';
import { Guid } from 'guid-typescript';
import { showErrorSnackbar, showSuccessSnackbar } from '../../component/snackbar/Snackbar.slice';
import { Messages } from '../../Constants/Messages';

export function addHandleImportNotification(importMessage: ImportMessage, dispatch: AppDispatch) {
	dispatch(
		handleInputNotification({
			signalrHubMethods: addNotification,
			importMessage: importMessage,
			notificationType: NotificationType.Group,
		}),
	);
	dispatch(
		setTemporaryImportNotificationStatusWidgetState({
			hierarchyLevelId: Guid.parse(importMessage.groupId),
			currentlyWith: importMessage.importedBy,
			lastChange: new Date(importMessage.importedOn),
		}),
	);
}

export function addHandleUserImportFailedNotification(importMessage: ImportMessage, dispatch: AppDispatch) {
	showErrorSnackbar(dispatch, Messages.IMPORT_FAILED);
	dispatch(
		handleInputNotification({
			signalrHubMethods: addUserNotification,
			importMessage: importMessage,
			notificationType: NotificationType.User,
		}),
	);
	dispatch(
		setTemporaryImportNotificationStatusWidgetState({
			hierarchyLevelId: Guid.parse(importMessage.groupId),
			currentlyWith: importMessage.importedBy,
			lastChange: new Date(importMessage.importedOn),
		}),
	);
}

export function addHandleUserImportSuccessNotification(importMessage: ImportMessage, dispatch: AppDispatch) {
	showSuccessSnackbar(dispatch, Messages.IMPORT_SUCCESSFUL);
	dispatch(
		handleInputNotification({
			signalrHubMethods: addUserNotification,
			importMessage: importMessage,
			notificationType: NotificationType.User,
		}),
	);
	dispatch(
		setTemporaryImportNotificationStatusWidgetState({
			hierarchyLevelId: Guid.parse(importMessage.groupId),
			currentlyWith: importMessage.importedBy,
			lastChange: new Date(importMessage.importedOn),
		}),
	);
}