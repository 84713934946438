import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../Types/Notifications.types';
import { HubConnection } from '@microsoft/signalr';
import { AppDispatch, RootState } from '../../app/store';
import { downloadErrorByCalculationId, downloadReportByCalculationId } from '../../api/GlobalActions/GlobalActions.api';
import { currDateAndTime, saveStream } from '../Utils/utils';
import { showErrorSnackbar, showSuccessSnackbar } from '../../component/snackbar/Snackbar.slice';
import { Messages } from '../../Constants/Messages';
import { DownloadCalculationReports } from '../Types/Calculation.types';
import { Guid } from 'guid-typescript';

export type setIsStandardReportEnabledPayload = {
	hierarchyLevelId: string;
	isStandardReportEnabled: boolean;
};

type State = {
	isNewNotification: number;
	clientNotificationList: Notification[];
	rsmNotificationList: Notification[];
	isSignalRConnected: boolean;
	currentGroup: string[];
	isStandardReportEnabled: boolean;
	id?: string;
};

const initialState: State = {
	isNewNotification: 0,
	clientNotificationList: [],
	rsmNotificationList: [],
	isSignalRConnected: false,
	currentGroup: [],
	isStandardReportEnabled: false,
	id: '',
};

export const signalRConnected = createAsyncThunk<
	void,
	{ hubConnection: HubConnection },
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('app/signalRConnected', async (params, thunkAPI) => {
	thunkAPI.dispatch(setIsSignalRConnected(true));
});

export const joinGroup = createAsyncThunk<
	void,
	{
		hubConnection: HubConnection;
		groupId: string;
	},
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('app/joinGroup', async (params, thunkAPI) => {
	await params.hubConnection.invoke('JoinGroup', params.groupId);
	thunkAPI.dispatch(setJoinGroup(params.groupId.toString()));
});

export const leaveGroup = createAsyncThunk<
	void,
	{
		hubConnection: HubConnection;
		groupId: string;
	},
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('app/leaveGroup', async (params, thunkAPI) => {
	await params.hubConnection.invoke('LeaveGroup', params.groupId);
	thunkAPI.dispatch(setLeaveGroup(params.groupId));
});

export const downloadCalculationReports = createAsyncThunk<void, DownloadCalculationReports>(
	'globalActions/downloadOutputReports',
	async (params, thunkAPI) => {
		const response = await downloadReportByCalculationId(params.calculationId.toString());
		const fileName = `${currDateAndTime()}_${params.jobId}_Calculation_Reports`;
		if (response.Success) {
			saveStream(response.ContentType ?? null, fileName, response.Data);
			showSuccessSnackbar(thunkAPI.dispatch, Messages.DOWNLOAD_OUTPUT_REPORTS_SUCCESS);
		} else {
			showErrorSnackbar(thunkAPI.dispatch, Messages.DOWNLOAD_OUTPUT_REPORTS_FAILED);
		}
	},
);

export const downloadCalculationErrors = createAsyncThunk<void, Guid>(
	'globalActions/downloadCalculationErrors',
	async (calculationId, thunkAPI) => {
		const response = await downloadErrorByCalculationId(calculationId.toString());
		const fileName = `${currDateAndTime()}_${calculationId}_Calculation_Errors`;
		if (response.Success) {
			saveStream(response.ContentType ?? null, fileName, response.Data);
			showSuccessSnackbar(thunkAPI.dispatch, Messages.DOWNLOAD_CALCULATION_ERROR_SUCCESS);
		} else {
			showErrorSnackbar(thunkAPI.dispatch, Messages.DOWNLOAD_CALCULATION_ERROR_FAILED);
		}
	},
);

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addNotification(state, action: PayloadAction<Notification>): any {
			const localNotificationList = [...state.clientNotificationList];
			localNotificationList.unshift(action.payload);
			if (localNotificationList.length > 10) {
				localNotificationList.pop();
			}
			return { ...state, clientNotificationList: localNotificationList, isNewNotification: 1 };
		},
		addUserNotification(state, action: PayloadAction<Notification>): any {
			if (!state.currentGroup.includes(action.payload.groupId?.toLowerCase())) {
				const localNotificationList = [...state.rsmNotificationList];
				localNotificationList.unshift(action.payload);
				if (localNotificationList.length > 10) {
					localNotificationList.pop();
				}
				return { ...state, rsmNotificationList: localNotificationList, isNewNotification: 1 };
			}
		},
		removeNotificationDot(state): State {
			return { ...state, isNewNotification: 0 };
		},
		setIsSignalRConnected: (state, action: PayloadAction<boolean>) => {
			state.isSignalRConnected = action.payload;
		},
		setJoinGroup: (state, action: PayloadAction<string>) => {
			let groupState = [...state.currentGroup];
			if (action.payload && !groupState.includes(action.payload?.toString())) {
				groupState.push(action.payload.toLowerCase());
			}
			return { ...state, currentGroup: groupState };
		},
		setLeaveGroup: (state, action: PayloadAction<string>) => {
			let prevGroupState = [...state.currentGroup];
			const newGroupState = prevGroupState.filter((item) => item !== action.payload.toLowerCase());
			return { ...state, currentGroup: newGroupState };
		},
		setIsStandardReportEnabledFlag: (state, action: PayloadAction<setIsStandardReportEnabledPayload>) => {
			if (!state.currentGroup.includes(action.payload?.hierarchyLevelId.toLowerCase())) {
				return state;
			}
			return { ...state, isStandardReportEnabled: action.payload.isStandardReportEnabled };
		},
	},
});

export const {
	addNotification,
	addUserNotification,
	removeNotificationDot,
	setIsSignalRConnected,
	setJoinGroup,
	setLeaveGroup,
	setIsStandardReportEnabledFlag,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
