import { FetchApi } from '../ApiFetch/ApiFetch';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { Guid } from 'guid-typescript';
import { ProcessId } from '../../core/Types/ProcessId.types';
import { CreateBlobResponse } from '../ApiFetch/ApiResponse';
import { ApiUrls } from '../ApiConstants/ApiUrls';

export async function downloadEmptyTemplate() {
	const response = await FetchApi({
		endpoint: 'template',
		method: HTTPMethod.Get,
		apiType: ServiceType.Import,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadActiveDataset(jobId: Guid, datasetId?: Guid) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint: 'job/' + jobId + '/dataset/' + datasetId,
		apiType: ServiceType.Import,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadReports(processId: ProcessId) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint:
			'report/client/' +
			processId.clientId +
			'/job/' +
			processId.jobId +
			'/import/' +
			processId.importId +
			'/download',
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadFile(fileId: string) {
	const url = `file-download/${fileId}`;
	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Get,
		apiType: ServiceType.Enrichment,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadReportByCalculationId(calculationId: string) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint: ApiUrls.DOWNLOAD_REPORT.replace('{calculationId}', calculationId),
		apiType: ServiceType.Calculation,
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadErrorByCalculationId(calculationId: string) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint: ApiUrls.DOWNLOAD_ERROR.replace('{calculationId}', calculationId),
		apiType: ServiceType.Calculation,
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}