export const TradeName = 'TR&alpha;DE';
export const RSMStandard = 'RSM Standard';
export const pageNotFoundPath = '/page-not-found';
export const unauthorizedPath = '/unauthorized';
export const title = 'User Manual';
export const topCalculation = 'Calculation History';
export const latestCalculation = 'Last 10 calculations';
export const datasetFileName = 'Dataset';
export const fileName = 'TRaDE User Guide';
export const deepLinkingTimeOut = 1500;
export const signalRTimeout = 5000;
export const signalRServerTimeoutInMilliseconds = 1000 * 60;
export const signalRKeepAliveIntervalInMilliseconds = 1000 * 20;
export const inputTemplateTooltipMessage = 'Successful dataset import required';
export const outputTooltipTitle = 'Successful calculation required';
export const ERROR_MESSAGE = 'An error occurred while fetching data. Please contact the engagement team.';
export const usageStatistics = 'Usage statistics for the last 180 days';
export const defaultChartValueMessage = '**The charts are populated with sample values. Please run a successful calculation for the actual values to be displayed.';
export const excelSheetContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
