import { Guid } from 'guid-typescript';
import { FetchApi } from '../ApiFetch/ApiFetch';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { AssetOfInterestImportFormData } from '../../pages/admin/assetsOfInterest/AssetOfInterest.types';
import { CreateBlobResponse, CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';

export const uploadClientAssetsOfInterest = async (
	formRequest: AssetOfInterestImportFormData,
): Promise<ResponseData> => {
	const formData = commonFormData(formRequest);
	const url = `assets-of-interest/client-upload/${formRequest.ClientId}`;
	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Post,
		apiType: ServiceType.Enrichment,
		body: formData,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const uploadRSMAssetsOfInterest = async (formRequest: AssetOfInterestImportFormData): Promise<ResponseData> => {
	const formData = commonFormData(formRequest);

	const response = await FetchApi({
		endpoint: 'assets-of-interest/upload',
		method: HTTPMethod.Post,
		apiType: ServiceType.Enrichment,
		body: formData,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const assetOfInterestRun = async (
	jobId: Guid,
	clientId: string,
	fileImportId: string,
): Promise<ResponseData> => {
	const url = `assets-of-interest/job/${jobId}/${fileImportId}/${clientId}/analyze`;
	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Post,
		apiType: ServiceType.Enrichment,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const fetchClientAssetOfInterestInput = async (clientId: string): Promise<ResponseData> => {
	const response = await FetchApi({
		endpoint: `assets-of-interest/client/${clientId}`,
		apiType: ServiceType.Enrichment,
		method: HTTPMethod.Get,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const fetchRSMAssetOfInterestInput = async (): Promise<ResponseData> => {
	const response = await FetchApi({
		endpoint: 'assets-of-interest',
		apiType: ServiceType.Enrichment,
		method: HTTPMethod.Get,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const updateClientAssetOfInterestInput = async (
	formRequest: AssetOfInterestImportFormData,
): Promise<ResponseData> => {
	const formData = commonFormData(formRequest);
	formData.append('FileId', formRequest.Id);
	const url = `assets-of-interest/client/${formRequest.ClientId}/update-metadata`;

	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Put,
		apiType: ServiceType.Enrichment,
		body: formData,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const updateRSMAssetOfInterestInput = async (
	formRequest: AssetOfInterestImportFormData,
): Promise<ResponseData> => {
	const formData = commonFormData(formRequest);
	formData.append('FileId', formRequest.Id);

	const response = await FetchApi({
		endpoint: 'assets-of-interest/update-metadata',
		method: HTTPMethod.Put,
		apiType: ServiceType.Enrichment,
		body: formData,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export async function downloadAssetOfInterestInput(fileId: string): Promise<ResponseData> {
	const url = `file-download/${fileId}`;
	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Get,
		apiType: ServiceType.Enrichment,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

const commonFormData = (assetOfInterestImportFormData: AssetOfInterestImportFormData) => {
	const formData = new FormData();
	formData.append('File', assetOfInterestImportFormData.File);
	formData.append('AssetsOfInterestFoundMessage', assetOfInterestImportFormData.AssetsOfInterestFoundMessage);
	formData.append('AssetsOfInterestListName', assetOfInterestImportFormData.ListName);
	formData.append('IsActive', `${assetOfInterestImportFormData.IsActive}`);
	formData.append('IsAssetIdSelected', `${assetOfInterestImportFormData.IsAssetIdSelected}`);
	formData.append('IsUnderlyingIdSelected', `${assetOfInterestImportFormData.IsUnderlyingIdSelected}`);
	return formData;
};
