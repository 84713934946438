import { FetchApi, FetchApiJson } from '../ApiFetch/ApiFetch';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { Guid } from 'guid-typescript';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';

export const fetchAnalysisSettings = async (): Promise<ResponseData> => {
	try {
		const response = await FetchApi({
			endpoint: 'calculationSettings/analysisgroups',
			apiType: ServiceType.Calculation,
			method: HTTPMethod.Get,
		});
		return CreateTextResponse(response);
	} catch (e) {
		throw e;
	}
};

export const fetchAnalysisSettingsByJob = async (jobId: Guid): Promise<ResponseData> => {
	try {
		const response = await FetchApi({
			endpoint: `calculationSettings/job/${jobId}`,
			apiType: ServiceType.Calculation,
			method: HTTPMethod.Get,
		});
		return CreateTextResponse(response);
	} catch (e) {
		throw e;
	}
};

export const uploadFormData = async (jobId: Guid, analysisSetting: any[]): Promise<ResponseData> => {
	const response = await FetchApi({
		endpoint: `calculationSettings/job/${jobId}`,
		headers: {
			'Content-Type': 'application/json',
		},
		method: HTTPMethod.Post,
		apiType: ServiceType.Calculation,
		body: JSON.stringify(analysisSetting),
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};
