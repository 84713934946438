import { CalculationSetting } from './CalculationSetting';
import {
	AccrualBasis,
	AnalysisGroup,
	AnalysisSettingsResponse,
	Bifurcation,
	Character,
	IRC475,
	ClientType,
	ForcedConstructiveSales,
	HedgingType,
	ReliefMethodOptions,
	Reporting,
} from './Settings.types';

export class DefaultCalculationSetting extends CalculationSetting {
	build = (analysisSettingsResponse: AnalysisSettingsResponse): any => {
		let jsonObject: any = {};

		jsonObject[AnalysisGroup.Calculation] = this.buildCalculationAnalysis(
			this.buildClientType(),
			this.buildStartDate(),
			this.buildEndDate(),
			this.buildReporting(),
			this.buildBifurcation()
		);
		jsonObject[AnalysisGroup.Dividend] = this.buildDividendAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.WashSales] = this.buildWashSalesAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.Payments] = this.buildNonSubSettingsAnalysis(analysisSettingsResponse, AnalysisGroup.Payments);
		jsonObject[AnalysisGroup.Straddles] = this.buildNonSubSettingsAnalysis(analysisSettingsResponse, AnalysisGroup.Straddles);
		jsonObject[AnalysisGroup.Hedging] = this.buildHedgingAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.ConstructiveSales] = this.buildConstructiveSalesAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.Character] = this.buildCharacterAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.IRC475] = this.buildIRC475Analysis(analysisSettingsResponse);

		return jsonObject;
	};

	buildDividendAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const dividendAnalysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.Dividend,
		);
		const dividendAnalysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.Dividend)
			.map((x) => x.analysisID.toString());
		return this.buildDividendAnalysisSettingValue(dividendAnalysis, dividendAnalysisSubSetting, true, AccrualBasis.Accrual);
	};

	buildWashSalesAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const washSalesAnalysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.WashSales,
		);
		const washSalesAnalysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.WashSales)
			.map((x) => x.analysisID.toString());
		return this.buildWashSalesAnalysisSettingValue(washSalesAnalysis, washSalesAnalysisSubSetting, false);
	};

	buildNonSubSettingsAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse, analysisGroup: AnalysisGroup) => {
		const allAnalysis = analysisSettingsResponse.analysis
			.filter((x) => x.analysisGroup === analysisGroup)
			.map((x) => x.analysisID.toString());
		return this.buildNonSubSettingsAnalysisSettingValue(allAnalysis);
	};

	buildHedgingAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const analysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.Hedging,
		);
		const analysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.Hedging)
			.map((x) => x.analysisID.toString());
		return this.buildHedgingAnalysisSettingValue(
			analysis,
			analysisSubSetting,
			HedgingType.OneToAll,
			ReliefMethodOptions.FIFO,
			ReliefMethodOptions.FIFO,
		);
	};

	buildConstructiveSalesAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const analysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.ConstructiveSales,
		);
		const analysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.ConstructiveSales)
			.map((x) => x.analysisID.toString());
		return this.buildConstructiveSalesAnalysisSettingValue(
			analysis,
			analysisSubSetting,
			ReliefMethodOptions.FIFO,
			ForcedConstructiveSales.Yes,
		);
	};

	buildCharacterAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const analysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.Character,
		);
		const analysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.Character)
			.map((x) => x.analysisID.toString());
		return this.buildAnalysisSettingValue(
			AnalysisGroup.Character,
			analysis,
			analysisSubSetting,
			Character.SixtyFortySplit
		);
	};

	buildIRC475Analysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const analysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.IRC475,
		);
		const analysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.IRC475)
			.map((x) => x.analysisID.toString());
		return this.buildAnalysisSettingValue(
			AnalysisGroup.IRC475,
			analysis,
			analysisSubSetting,
			IRC475.FourSevenFiveF1
		);
	};

	buildStartDate = (): Date => {
		const priorYear = new Date().getFullYear() - 1;
		return new Date(priorYear, 0, 1);
	};

	buildEndDate = (): Date => {
		const priorYear = new Date().getFullYear() - 1;
		return new Date(priorYear, 11, 31);
	};

	buildClientType = (): ClientType => {
		return ClientType.Investor;
	};
	buildReporting = (): Reporting => {
		return Reporting.Monthly;
	};
	buildBifurcation = (): Bifurcation => {
		return Bifurcation.Upload;
	};
}
